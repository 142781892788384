export const THEME_NAMES = {
  core: 'Core',
  enterpriseDark: 'EnterpriseDark',
  enterprise: 'Enterprise',
  pc: 'PCTheme',
  studio: 'Studio',
} as const;

export type ThemeNameValue = typeof THEME_NAMES[keyof typeof THEME_NAMES];

export const DESIGN_SYSTEM_COLORS = {
  grayscale: {
    '0': '#FFFFFF',
    '50': '#F8F8F8',
    '100': '#F2F2F2',
    '150': '#EFEFEF',
    '200': '#EDEDED',
    '250': '#ECECEC',
    '300': '#EBEBEB',
    '350': '#D8D8D8',
    '400': '#C7C7C7',
    '450': '#C4C4C4',
    '500': '#9DA27A',
    '550': '#9C9C9C',
    '600': '#868E96',
    '650': '#696969',
    '700': '#656565',
    '750': '#494949',
    '800': '#3A3A3A',
    '850': '#363636',
    '875': '#303132',
    '900': '#2F2E2E',
    '950': '#242424',
    '1000': '#000000',
  },
  blue: {
    '50': '#F9FCFF',
    '100': '#EEF7FF',
    '150': '#E3EFF3',
    '200': '#EEEFF3',
    '250': '#E0E7F2',
    '300': '#D2D9E5',
    '350': '#C5DBE7',
    '400': '#CAE7F7',
    '450': '#2C9EDE',
    '500': '#1A79AF',
    '600': '#13618E',
    '650': '#105075',
    '700': '#193E5F',
    '750': '#183342',
    '800': '#162833',
    '850': '#14222A',
    '900': '#13191C',
  },
  orange: {
    '100': '#FFFCF9',
    '200': '#FFF7ED',
    '300': '#FDF2E0',
    '400': '#FADEB1',
    '500': '#FFD100',
    '600': '#FEBF10',
    '700': '#F3B34C',
    '800': '#EF9500',
    '900': '#D75000',
  },
  purple: {
    '100': '#F1E7F5',
    '200': '#E7DCF2',
    '300': '#997FC0',
    '400': '#8365B3',
    '550': '#8E60C5',
    '500': '#422B7E',
    '600': '#611EEF',
    '700': '#22008C',
    '900': '#32262F',
  },
  red: {
    '100': '#FFEBED',
    '200': '#FFCCD0',
    '300': '#EE6C6C',
    '400': '#D0021B',
    '500': '#BE000F',
  },
  green: {
    '100': '#E7F4E5',
    '200': '#C5E3BF',
    '300': '#7ED321',
    '400': '#147600',
    '500': '#105F00',
  },
  brown: {
    '100': '#AF751A',
    '200': '#A4754B',
    '300': '#8E5213',
  },
  yellow: {
    '500': '#FDEE2E',
  },
  teal: {
    '100': '#BAE8DF',
  },
  paywall: {
    cool100: '#F9EFE8',
    cool400: '#F1D6C7',
    cool700: '#E2AD8E',
    cool800: '#789EE5',
    spicy700: '#008DD3',
    hot100: '#E4ECFA',
    hot400: '#C9D8F5',
    hot700: '#789EE5',
  },
};

export type DesignSystemColors = typeof DESIGN_SYSTEM_COLORS;

const STATUS_BG_COLORS = {
  statusRejected: DESIGN_SYSTEM_COLORS.red['100'],
  statusWarning: DESIGN_SYSTEM_COLORS.orange['100'],
  statusSelectedForOtherRoles: DESIGN_SYSTEM_COLORS.orange['800'],
  statusSubmittedForOtherRoles: DESIGN_SYSTEM_COLORS.blue['500'],
  statusSubmissionMovedBanner: DESIGN_SYSTEM_COLORS.grayscale['650'],
  statusSubmssionMovedCard: DESIGN_SYSTEM_COLORS.grayscale['250'],
  statusSubmissionSelectedBanner: DESIGN_SYSTEM_COLORS.blue['700'],
  statusSubmssionSelectedCard: DESIGN_SYSTEM_COLORS.blue['150'],
  statusSubmissionReviewedBanner: DESIGN_SYSTEM_COLORS.grayscale['1000'],
  statusSubmssionReviewedCard: DESIGN_SYSTEM_COLORS.grayscale['100'],
  statusProfileHiddenBanner: DESIGN_SYSTEM_COLORS.grayscale['400'],
};

const STATUS_COLORS = {
  statusActive: DESIGN_SYSTEM_COLORS.green['400'],
  statusSuccess: DESIGN_SYSTEM_COLORS.green['400'],
  statusRejected: DESIGN_SYSTEM_COLORS.red['400'],
  statusWarning: DESIGN_SYSTEM_COLORS.orange['600'],
  statusInternal: DESIGN_SYSTEM_COLORS.brown['200'],
  statusDefault: DESIGN_SYSTEM_COLORS.grayscale['550'],
  statusChangesRequested: DESIGN_SYSTEM_COLORS.orange['900'],
  statusInvited: DESIGN_SYSTEM_COLORS.orange['800'],
  statusUnderReview: DESIGN_SYSTEM_COLORS.purple['400'],
  statusClosed: DESIGN_SYSTEM_COLORS.grayscale['800'],
  statusDisabled: DESIGN_SYSTEM_COLORS.grayscale['400'],
  statusRequestAdditionalSubmissions: DESIGN_SYSTEM_COLORS.purple['900'],
  statusSelectionTagNotSelected: DESIGN_SYSTEM_COLORS.blue['500'],
  statusSelectionTagNotSelectedFocus: DESIGN_SYSTEM_COLORS.blue['300'],
  statusSelectionTagSelected: DESIGN_SYSTEM_COLORS.orange['800'],
  statusSelectionTagSelectedFocus: DESIGN_SYSTEM_COLORS.orange['500'],
} as const;

export const DEFAULT_DESIGN_SYSTEM_THEME = {
  bgColors: {
    /**
     * The standard page background color, this should be used for the
     * root page element
     * As seen as the background color for most of these components:
     * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1247%3A28249&t=TJC8mLYKTtZvByeD-0
     */
    pageBody: DESIGN_SYSTEM_COLORS.blue['200'],
    /**
     * Used as the background for most "containers" in your pages
     * From the baseball card to stand-alone forms on top of the body
     * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1247%3A28249&t=TJC8mLYKTtZvByeD-0
     */
    card: DESIGN_SYSTEM_COLORS.grayscale['0'],
    /** used as the background for an inverted card or card header with contrast bg */
    cardInverted: DESIGN_SYSTEM_COLORS.grayscale['800'],
    /** used as bg color for thrive benefits cards */
    thriveCard: DESIGN_SYSTEM_COLORS.purple['550'],
    thriveLoadingBar: DESIGN_SYSTEM_COLORS.grayscale['350'],
    thriveLoadingBarProgress: DESIGN_SYSTEM_COLORS.blue['500'],
    // disabled: background color for inputs or disabled elements
    disabled: DESIGN_SYSTEM_COLORS.grayscale['350'],
    // Shown for containers that need a danger affordance, can be used with standard text colors
    danger: DESIGN_SYSTEM_COLORS.red['100'],

    // Show for containers that are slightly less important than danger
    warning: DESIGN_SYSTEM_COLORS.orange['200'],
    info: DESIGN_SYSTEM_COLORS.blue['100'],
    // standard disabled bg color for inputs
    inputDisabled: DESIGN_SYSTEM_COLORS.grayscale['50'],

    // Used by the new upload media widget as the background color for the dropzone
    uploadMediaModal: DESIGN_SYSTEM_COLORS.grayscale['250'],

    // Used by the publish role modal
    modalDetail: DESIGN_SYSTEM_COLORS.grayscale['250'],

    // Used by the "Upgrade" components to indicate a primary action
    upgradeCardsPrimary: DESIGN_SYSTEM_COLORS.blue['500'],
    // Used by the "Upgrade" components in the modal variety to indicate a primary action
    upgradeCardsPrimaryAlternate: DESIGN_SYSTEM_COLORS.orange['800'],

    // comboboxToken: background used for multiselect tokens in a combobox
    comboboxToken: DESIGN_SYSTEM_COLORS.blue['100'],
    // svgGraphicDefaultFill: used as the default fill color in themed SVGs
    svgGraphicDefaultFill: DESIGN_SYSTEM_COLORS.blue['500'],
    // svgGraphicDarkFill: used as a secondary, darker fill color in themed SVGs
    svgGraphicDarkFill: DESIGN_SYSTEM_COLORS.blue['700'],
    // listItem: an item in a list that has its own background (slightly off from card bg color)
    listItem: DESIGN_SYSTEM_COLORS.grayscale['50'],
    // impactedServiceAlert: see packages/blocs/src/components/ImpactedServiceAlert/ImpactedServiceAlert.tsx
    impactedServiceAlert: DESIGN_SYSTEM_COLORS.orange['300'],

    // Default color for panel headers for lists and forms
    // see example talent search -> representatives lits -> create new list
    panelHeader: DESIGN_SYSTEM_COLORS.blue['600'],

    // presentation page bg colors (casting => submissions => presentation links => view a presentation)
    // presentationWrapper: the color for the background of the entire presentation page
    presentationWrapper: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // presentationHeaderMd: used for the header of the presentation's group list page on large screen sizes
    presentationHeaderMd: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // presentationHeaderSm: used for the header of the presentation's group list page on small screen sizes
    presentationHeaderSm: DESIGN_SYSTEM_COLORS.blue['700'],
    // presentationOrderNumber: used for the order numeral that is overlaid on the session in a presentation group detail
    presentationOrderNumber: DESIGN_SYSTEM_COLORS.blue['800'],
    // presentationThumbnail: used for the thumbnail of the session in a presentation group detail when the image does not fill the full container
    presentationThumbnail: DESIGN_SYSTEM_COLORS.grayscale['800'],
    // presentationMediaSection: used when a session is selected, this is the background that the group media displays over (only shows on mobile size)
    presentationMediaSection: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // presentationGroupDetail: used when a session is selected, this is the bg that toggles open when selecting a session
    presentationGroupDetail: DESIGN_SYSTEM_COLORS.blue['250'],
    // presentationGroupTab: used when a session is selected, this is the bg that the media sits in
    presentationGroupTab: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // logoPlaceholder: bg color used in the logo area when a logo is not set
    logoPlaceholder: DESIGN_SYSTEM_COLORS.grayscale['550'],
    // tableHeader: bg color that is the default for the header row of a table
    tableHeader: DESIGN_SYSTEM_COLORS.blue['300'],
    // bg for a selection tag that is not selected
    selectionTag: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // bg for a selection tag that is not selected during hover
    selectionTagHover: DESIGN_SYSTEM_COLORS.grayscale['100'],
    // bg for a selection tag that is not selected during an active click or press
    selectionTagActive: DESIGN_SYSTEM_COLORS.grayscale['200'],
    // bg for a selection tag that is selected (here from older implementation)
    selectionTagHighlight: DESIGN_SYSTEM_COLORS.orange['800'],
    // bg for a selection tag that is selected
    selectionTagSelected: DESIGN_SYSTEM_COLORS.orange['800'],
    // bg for a selection tag that is selected during an active click or press
    selectionTagSelectedActive: DESIGN_SYSTEM_COLORS.orange['900'],
    // bg for a selection tag that is selected during a hover
    selectionTagSelectedHover: DESIGN_SYSTEM_COLORS.orange['700'],
    // profile type banner in studio's talent card
    profileTypeBanner: DESIGN_SYSTEM_COLORS.grayscale['100'],
    // icon square background
    iconSquareBg: DESIGN_SYSTEM_COLORS.grayscale['100'],
    // icon square hover state
    iconSquareHover: DESIGN_SYSTEM_COLORS.grayscale['200'],
    // talent pool role container
    talentPoolRoleContainer: DESIGN_SYSTEM_COLORS.grayscale['0'],

    // Base background color for select components. Used in <CopyToOtherSessionSelect />
    select: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // Inverted background color for select components. Used in <CopyToOtherSessionSelect />
    selectInverted: DESIGN_SYSTEM_COLORS.blue['800'],
    // Used on the Login page for the "Welcome" banner
    headerBanner: DESIGN_SYSTEM_COLORS.blue['100'],

    // Location and date picker background colors to support both wizard and non-wizard flows
    LocationAndDatePickerIsPrimary: DESIGN_SYSTEM_COLORS.grayscale['0'],
    LocationAndDatePicker: DESIGN_SYSTEM_COLORS.grayscale['50'],

    // Header box for notifications for create role and wizard role create
    headerBox: DESIGN_SYSTEM_COLORS.grayscale['50'],
    headerBoxPrimary: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // Wizard page background color
    wizardPageBody: DESIGN_SYSTEM_COLORS.blue['50'],
    wizardWrapperBg: DESIGN_SYSTEM_COLORS.grayscale['50'],
    // Media thumnail message background
    mediaBottomLabelBg: DESIGN_SYSTEM_COLORS.grayscale['300'],
    // Credit card priority tags (typically showing things like Primary/Backup)
    creditCardPriorityTag: DESIGN_SYSTEM_COLORS.orange['300'],
    transparent: 'transparent',
    // bg color for overlay text on a photo or thubmnail
    photoOverlay: DESIGN_SYSTEM_COLORS.grayscale['50'],
    // spicerack bg color
    spicerack: DESIGN_SYSTEM_COLORS.grayscale['50'],
    // standard color for baseball card footer (contact info, submission notes)
    baseballCardFooter: DESIGN_SYSTEM_COLORS.grayscale['250'],
    // baseball card after being selected
    baseballCardSelected: DESIGN_SYSTEM_COLORS.blue['700'],
    // baseball card after being moved
    baseballCardMoved: DESIGN_SYSTEM_COLORS.grayscale['650'],
    // baseball card after being reviewed
    baseballCardReviewed: DESIGN_SYSTEM_COLORS.grayscale['1000'],
    // The background color for the "Primary Photo" banner
    mediaBankPrimary: DESIGN_SYSTEM_COLORS.grayscale['650'],
    mediaBankLockedOverlay: DESIGN_SYSTEM_COLORS.grayscale['750'],

    // can be used for alerts or other status-aware components
    ...STATUS_BG_COLORS,
    // Next Paywall experience background colors
    paywallOrderDetail: DESIGN_SYSTEM_COLORS.grayscale['200'],
    paywallPrompt: DESIGN_SYSTEM_COLORS.orange['200'],
    paywallCool: DESIGN_SYSTEM_COLORS.paywall.cool700,
    paywallHot: DESIGN_SYSTEM_COLORS.paywall.hot700,
    paywallWarm: DESIGN_SYSTEM_COLORS.green['400'],
    paywallVanilla: DESIGN_SYSTEM_COLORS.grayscale['350'],
    coolPaywallFeaturesBg: DESIGN_SYSTEM_COLORS.paywall.cool100,
    hotPaywallFeaturesBg: DESIGN_SYSTEM_COLORS.paywall.hot100,
    hotTrialPaywallFeaturesBg: DESIGN_SYSTEM_COLORS.paywall.hot100,
    coolTrialPaywallFeaturesBg: DESIGN_SYSTEM_COLORS.paywall.hot100,
    vanillaPaywallFeaturesBg: DESIGN_SYSTEM_COLORS.grayscale['50'],
    coolPaywallDarkBg: DESIGN_SYSTEM_COLORS.paywall.cool400,
    hotPaywallDarkBg: DESIGN_SYSTEM_COLORS.paywall.hot400,
    hotTrialPaywallDarkBg: DESIGN_SYSTEM_COLORS.paywall.hot400,
    coolTrialPaywallDarkBg: DESIGN_SYSTEM_COLORS.paywall.hot400,
    vanillaPaywallDarkBg: DESIGN_SYSTEM_COLORS.grayscale['100'],
    paywallTrialBadge: DESIGN_SYSTEM_COLORS.green['200'],
    coolPaywallSuccessBg: DESIGN_SYSTEM_COLORS.paywall.cool700,
    hotPaywallSuccessBg: DESIGN_SYSTEM_COLORS.paywall.hot700,
    vanillaPaywallSuccessBg: DESIGN_SYSTEM_COLORS.grayscale['350'],
    paywallBestDealBg: DESIGN_SYSTEM_COLORS.green['200'],
    roleHeaderTitleAdmin: DESIGN_SYSTEM_COLORS.blue['300'],
    roleHeaderAdmin: DESIGN_SYSTEM_COLORS.blue['250'],
    roleSubmissionDueAdmin: DESIGN_SYSTEM_COLORS.grayscale['250'],
    activeOpenCall: DESIGN_SYSTEM_COLORS.green['400'],
    RequestStatusSentAndPendingBg: DESIGN_SYSTEM_COLORS.grayscale['800'],
    RequestStatusConfirmedBg: DESIGN_SYSTEM_COLORS.orange['800'],
    RequestStatusDeclinedBg: DESIGN_SYSTEM_COLORS.red['400'],
    RequestStatusReceivedResponseBg: DESIGN_SYSTEM_COLORS.green['400'],

    spicyPaywallFeaturesBg: DESIGN_SYSTEM_COLORS.blue['150'],
    spicyPaywallDarkBg: DESIGN_SYSTEM_COLORS.blue['150'],
    spicyPaywallSuccessBg: DESIGN_SYSTEM_COLORS.blue['150'],
    paywallActiveToggle: DESIGN_SYSTEM_COLORS.blue['500'],

    // forms selected options
    formsReportSelectedOption: DESIGN_SYSTEM_COLORS.grayscale['700'],
    // pill component
    pillSelected: DESIGN_SYSTEM_COLORS.grayscale['650'],
    pillUnselected: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // primary media banner
    primaryMedia: DESIGN_SYSTEM_COLORS.grayscale['650'],
  },
  /**
   * The standard text colors as defined in the design system:
   * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=209%3A1589&t=TJC8mLYKTtZvByeD-0
   */
  textColors: {
    default: DESIGN_SYSTEM_COLORS.grayscale['800'],
    primary: DESIGN_SYSTEM_COLORS.blue['500'],
    link: DESIGN_SYSTEM_COLORS.blue['500'],
    linkHover: DESIGN_SYSTEM_COLORS.blue['600'],
    linkActive: DESIGN_SYSTEM_COLORS.blue['650'],
    disabled: DESIGN_SYSTEM_COLORS.grayscale['650'],
    inverted: DESIGN_SYSTEM_COLORS.grayscale['0'],
    emphasis: DESIGN_SYSTEM_COLORS.grayscale['1000'],
    error: DESIGN_SYSTEM_COLORS.red['400'],
    warning: DESIGN_SYSTEM_COLORS.orange['900'],
    success: DESIGN_SYSTEM_COLORS.green['400'],
    accent: DESIGN_SYSTEM_COLORS.blue['700'],
    lightAccent: DESIGN_SYSTEM_COLORS.blue['650'],

    // Default color for panel headers text for lists and forms
    // see example talent search -> representatives lits -> create new list
    panelHeader: DESIGN_SYSTEM_COLORS.grayscale['0'],

    // Used by the new upload media widget as the text color for the dropzone
    lowContrastText: DESIGN_SYSTEM_COLORS.grayscale['400'],

    // presentation page bg colors (casting => submissions => presentation links => view a presentation)
    // presentationHeaderSm: used for the header of the presentation's group list page on small screen sizes
    presentationHeaderSm: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // presentationHeaderMd: used for the header of the presentation's group list page on large screen sizes
    presentationHeaderMd: DESIGN_SYSTEM_COLORS.grayscale['800'],
    // presentationHeaderLabel: renders "PROJECT" and "PRESENTATION" labels in the header
    presentationHeaderLabel: DESIGN_SYSTEM_COLORS.grayscale['350'],
    // presentationOrderNumber: used for the order numeral that is overlaid on the session in a presentation group detail
    presentationOrderNumber: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // presentationRecipientHeading: renders the presentation name and expiration date on mobile
    presentationRecipientHeading: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // presentationFolderName: used for presentation folder name in presentation folders list
    presentationFolderName: DESIGN_SYSTEM_COLORS.blue['500'],
    // logoPlaceholder: used in the logo area when a logo is not set
    logoPlaceholder: DESIGN_SYSTEM_COLORS.grayscale['0'],
    // selectedStar: used for the fill color of the star for selected highlights
    selectedStar: DESIGN_SYSTEM_COLORS.orange['500'],
    selectionTag: DESIGN_SYSTEM_COLORS.blue['450'],
    selectionTagHighlight: DESIGN_SYSTEM_COLORS.grayscale['0'],

    // Base text color for select components. Used in <CopyToOtherSessionSelect />
    select: DESIGN_SYSTEM_COLORS.blue['450'],
    // Inverted text color for select components. Used in <CopyToOtherSessionSelect />
    selectInverted: DESIGN_SYSTEM_COLORS.grayscale['0'],
    paywallUpgradeText: DESIGN_SYSTEM_COLORS.blue['500'],
    paywallVanilla: DESIGN_SYSTEM_COLORS.grayscale['800'],
    gridHeader: DESIGN_SYSTEM_COLORS.grayscale['950'],
    // Text for Pills that are unselected
    pillUnselected: DESIGN_SYSTEM_COLORS.grayscale['650'],
    // Thrive colors
    thriveHeader: DESIGN_SYSTEM_COLORS.purple['550'],
    // since we need a clip path to show the gradient, we need to set this to transparent in combination with the .cn_gradient-header-thrive-plus class
    thrivePlusHeader: 'transparent',
    ...STATUS_COLORS,
  },
  borderColors: {
    transparent: 'transparent',
    primary: DESIGN_SYSTEM_COLORS.blue['500'],
    default: DESIGN_SYSTEM_COLORS.grayscale['350'],
    inverted: DESIGN_SYSTEM_COLORS.grayscale['0'],
    heavyOutline: DESIGN_SYSTEM_COLORS.grayscale['400'],
    // Used when the component has an error within it (think inputs):
    error: DESIGN_SYSTEM_COLORS.red['400'],
    // Used for borders with a danger affordance
    danger: DESIGN_SYSTEM_COLORS.red['400'],
    // Used for containers that need a "warning" affordance
    warning: DESIGN_SYSTEM_COLORS.orange['600'],
    info: DESIGN_SYSTEM_COLORS.blue['500'],
    // used for when a link is focused
    linkFocus: DESIGN_SYSTEM_COLORS.blue['600'],
    // For input elements "outline" attribute when they are focused:
    inputFocus: DESIGN_SYSTEM_COLORS.blue['400'],
    // For input elements when focused with an error, this the color of the "focus" border:
    inputFocusError: DESIGN_SYSTEM_COLORS.red['200'],

    // Used for the FastCapture Live Session join block
    fastCaptureLiveBlock: DESIGN_SYSTEM_COLORS.blue['500'],

    // The "Upgrade" experience primary container with the "best deal" sales pitch
    upgradeCardsPrimary: DESIGN_SYSTEM_COLORS.blue['500'],
    // The "Upgrade" experience but in modal form where we wish to show it in a more "orange" color
    upgradeCardsPrimaryAlternate: DESIGN_SYSTEM_COLORS.orange['800'],
    // The "Upgrade" experience but with a less popular and lower priority sales pitch
    upgradeCardsSecondary: DESIGN_SYSTEM_COLORS.grayscale['350'],

    // used when a selected item is highlighted by a border
    selectedItem: DESIGN_SYSTEM_COLORS.blue['250'],

    // presentation page bg colors (casting => submissions => presentation links => view a presentation)
    // presentationNameDivider: divider used between project and presentation name in the header (light mode only)
    presentationNameDivider: DESIGN_SYSTEM_COLORS.grayscale['350'],
    // presentationNav: bottom border of presentation header section (light mode only)
    presentationNav: DESIGN_SYSTEM_COLORS.grayscale['350'],
    // highlightedCustomization: border around a customized media or credit item that is highlighted
    highlightedCustomization: DESIGN_SYSTEM_COLORS.blue['600'],

    // Base border color for select components. Used in <CopyToOtherSessionSelect />
    select: DESIGN_SYSTEM_COLORS.blue['450'],
    // Inverted border color for select components. Used in <CopyToOtherSessionSelect />
    selectInverted: DESIGN_SYSTEM_COLORS.blue['800'],
    // Next Paywall experience Free plan border color
    paywallVanilla: DESIGN_SYSTEM_COLORS.grayscale['350'],
    // Next Paywall experience plan border color

    spicyPaywall: DESIGN_SYSTEM_COLORS.blue['150'],

    coolPaywall: DESIGN_SYSTEM_COLORS.paywall.cool700,
    hotPaywall: DESIGN_SYSTEM_COLORS.paywall.hot700,
    hotTrialPaywall: DESIGN_SYSTEM_COLORS.paywall.hot700,
    coolTrialPaywall: DESIGN_SYSTEM_COLORS.paywall.cool800,
    vanillaPaywall: DESIGN_SYSTEM_COLORS.grayscale['650'],
    hotTrialUpsellPaywall: DESIGN_SYSTEM_COLORS.green['400'],
    coolTrialUpsellPaywall: DESIGN_SYSTEM_COLORS.green['400'],
    paywallTrialBadge: DESIGN_SYSTEM_COLORS.green['200'],
    mediaStorageAmount: DESIGN_SYSTEM_COLORS.grayscale['750'],
    mediaStorageType: DESIGN_SYSTEM_COLORS.grayscale['750'],
    emphasis: DESIGN_SYSTEM_COLORS.grayscale['800'],
    divider: DESIGN_SYSTEM_COLORS.grayscale['600'],

    // forms report border color
    formsReport: DESIGN_SYSTEM_COLORS.blue['500'],
    // pill border color
    pill: DESIGN_SYSTEM_COLORS.grayscale['650'],

    ...STATUS_COLORS,
  },
  boxShadows: {
    selectionTag: DESIGN_SYSTEM_COLORS.blue['450'],
    selectionTagHighlight: DESIGN_SYSTEM_COLORS.orange['800'],
  },
  sectionBreak: {
    /**
     * The main dividing line between the heading and the body of any given page
     * See "Page Title":
     * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1155%3A13&t=TJC8mLYKTtZvByeD-0
     */
    page: DESIGN_SYSTEM_COLORS.grayscale['800'],
    /**
     * The general dividing line between sections of a page when the background
     * is all the same color
     * See "Role - Expanded" as an example:
     * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1176%3A21792&t=TJC8mLYKTtZvByeD-0
     */
    divider: DESIGN_SYSTEM_COLORS.grayscale['350'],
  },
  /**
   * Used for the step wizard molecule component
   *
   * completedStep, activeStep and Step values are for the header bar
   * wizard step values
   *
   * Example design here
   * https://www.figma.com/file/Lw7DgrLZj850OsQTJOKAOs/JIRA---B21-28339---Casting---Project-Creation---%22Quick-Project-Creation%22-workflow?node-id=1762-25782&t=T95RixqUsqvs64s7-0
   */
  wizard: {
    background: DESIGN_SYSTEM_COLORS.grayscale['50'],

    titleHeader: DESIGN_SYSTEM_COLORS.grayscale['0'],
    completedStepBackgroundColor: DESIGN_SYSTEM_COLORS.blue['500'],
    completedStepBorder: DESIGN_SYSTEM_COLORS.blue['500'],

    activeStepBackgroundColor: DESIGN_SYSTEM_COLORS.grayscale['0'],
    activeStepColor: DESIGN_SYSTEM_COLORS.blue['500'],
    activeStepBorder: DESIGN_SYSTEM_COLORS.blue['500'],

    stepBackgroundColor: DESIGN_SYSTEM_COLORS.grayscale['0'],
    stepColor: DESIGN_SYSTEM_COLORS.grayscale['550'],
    stepBorder: DESIGN_SYSTEM_COLORS.grayscale['550'],

    footer: {
      border: DESIGN_SYSTEM_COLORS.grayscale['350'],
      background: DESIGN_SYSTEM_COLORS.grayscale['0'],
    },
  },
  paywall: {
    spicy: {
      bgColor: '#E2AD8E',
      darkBgColor: '#F1D6C7',
      color: DESIGN_SYSTEM_COLORS.grayscale['800'],
      iconColor: '#E2AD8E',
      featuresBgColor: '#F9EFE8',
      borderColor: '#E2AD8E',
      compareFeaturesColor: '#E2AD8E',
    },
    cool: {
      bgColor: '#E2AD8E',
      darkBgColor: '#F1D6C7',
      color: DESIGN_SYSTEM_COLORS.grayscale['800'],
      iconColor: '#E2AD8E',
      featuresBgColor: '#F9EFE8',
      borderColor: '#E2AD8E',
      compareFeaturesColor: '#E2AD8E',
    },
    hot: {
      bgColor: '#789EE5',
      darkBgColor: '#C9D8F5',
      color: DESIGN_SYSTEM_COLORS.grayscale['0'],
      iconColor: '#789EE5',
      featuresBgColor: '#E4ECFA',
      borderColor: '#789EE5',
      compareFeaturesColor: '#789EE5',
    },
    vanilla: {
      bgColor: '#D8D8D8',
      color: DESIGN_SYSTEM_COLORS.grayscale['800'],
      iconColor: DESIGN_SYSTEM_COLORS.grayscale['800'],
      featuresBgColor: DESIGN_SYSTEM_COLORS.grayscale['50'],
      darkBgColor: DESIGN_SYSTEM_COLORS.grayscale['100'],
      borderColor: DESIGN_SYSTEM_COLORS.grayscale['100'],
      compareFeaturesColor: DESIGN_SYSTEM_COLORS.grayscale['50'],
    },
  },
  progressIndicator: {
    background: DESIGN_SYSTEM_COLORS.grayscale[400],
    foreground: DESIGN_SYSTEM_COLORS.blue[500],
  },
  iconColors: {
    active: DESIGN_SYSTEM_COLORS.grayscale['800'],
    inactive: DESIGN_SYSTEM_COLORS.grayscale['400'],
    // used when the display color is inverted from default
    inverted: DESIGN_SYSTEM_COLORS.grayscale['0'],
    /**
     * This color is used to highlight critical information.
     * See "overscale" indicator:
     * https://www.figma.com/file/IFDGanU87Zd2Bn4SannLU7/JIRA---B21-25938---Casting---Baseball-Card---Make-overscale-icon-red?node-id=1908-9338&t=fDx8HAPwPqcOrWLc-0
     */
    critical: DESIGN_SYSTEM_COLORS.red['500'],
    /**
     * Used for a primary icon
     * Typically used for display purposes, see "Your Subscription" items:
     * https://www.figma.com/file/yTqnUIfiMsdyo0fgLfjXjG/CICN-1560---Talent---Membership-%26-Billing---Support-Manual-Renewal-of-Annual-Subscriptions?node-id=2409%3A6041&mode=dev
     */
    primary: DESIGN_SYSTEM_COLORS.blue['500'],
    success: DESIGN_SYSTEM_COLORS.green['400'],
    danger: DESIGN_SYSTEM_COLORS.red['400'],
    info: DESIGN_SYSTEM_COLORS.grayscale['800'],
    // Used by Edit Form warning message and warning icon
    editFormWarning: DESIGN_SYSTEM_COLORS.orange['800'],
    warning: DESIGN_SYSTEM_COLORS.orange['600'],
    statusDefault: DESIGN_SYSTEM_COLORS.grayscale['550'],
    statusActive: DESIGN_SYSTEM_COLORS.green['400'],
    mediaAudio: DESIGN_SYSTEM_COLORS.grayscale['650'],
    //  Paywall icon colors
    spicyPaywall: DESIGN_SYSTEM_COLORS.paywall.spicy700,
    coolPaywall: DESIGN_SYSTEM_COLORS.paywall.cool700,
    hotPaywall: DESIGN_SYSTEM_COLORS.paywall.hot700,
    hotTrialPaywall: DESIGN_SYSTEM_COLORS.paywall.hot700,
    coolTrialPaywall: DESIGN_SYSTEM_COLORS.paywall.cool800,
    vanillaPaywall: DESIGN_SYSTEM_COLORS.grayscale['800'],
    // Media bank icons:
    mediaInactive: DESIGN_SYSTEM_COLORS.grayscale['550'],
  },
  mediaColors: {
    audio: DESIGN_SYSTEM_COLORS.purple['400'],
    default: DESIGN_SYSTEM_COLORS.grayscale['350'],
    draft: DESIGN_SYSTEM_COLORS.green['400'],
    pending: DESIGN_SYSTEM_COLORS.grayscale['600'],
    photo: DESIGN_SYSTEM_COLORS.blue['500'],
    total: DESIGN_SYSTEM_COLORS.blue['500'],
    video: DESIGN_SYSTEM_COLORS.orange['600'],
  },
} as const;

export type StatusColor = keyof typeof STATUS_COLORS;

export type DesignSystemTheme = typeof DEFAULT_DESIGN_SYSTEM_THEME;

export type DesignSystemBgColor = keyof DesignSystemTheme['bgColors'];

export type DesignSystemIconColor = keyof DesignSystemTheme['iconColors'];

export type DesignSystemSectionBreakColor = keyof DesignSystemTheme['sectionBreak'];

export type DesignSystemTextColor = keyof DesignSystemTheme['textColors'];

export type DesignSystemBorderColor = keyof DesignSystemTheme['borderColors'];

export type DesignSystemMediaColor = keyof DesignSystemTheme['mediaColors'];

export const DEFAULT_DESIGN_SYSTEM_DARK_THEME: Partial<DesignSystemTheme> = {
  bgColors: {
    ...DEFAULT_DESIGN_SYSTEM_THEME.bgColors,
    pageBody: DESIGN_SYSTEM_COLORS.blue['900'],
    listItem: DESIGN_SYSTEM_COLORS.blue['800'],
    presentationWrapper: DESIGN_SYSTEM_COLORS.blue['900'],
    presentationHeaderMd: DESIGN_SYSTEM_COLORS.blue['800'],
    presentationHeaderSm: DESIGN_SYSTEM_COLORS.blue['800'],
    presentationOrderNumber: DESIGN_SYSTEM_COLORS.blue['850'],
    presentationMediaSection: DESIGN_SYSTEM_COLORS.grayscale['1000'],
    presentationGroupDetail: DESIGN_SYSTEM_COLORS.grayscale['1000'],
    presentationGroupTab: DESIGN_SYSTEM_COLORS.blue['800'],
    iconSquareHover: DESIGN_SYSTEM_COLORS.purple['200'],
  },
  textColors: {
    ...DEFAULT_DESIGN_SYSTEM_THEME.textColors,
    default: DESIGN_SYSTEM_COLORS.grayscale['250'],
    presentationHeaderMd: DESIGN_SYSTEM_COLORS.grayscale['0'],
    presentationHeaderLabel: DESIGN_SYSTEM_COLORS.grayscale['350'],
    presentationFolderName: DESIGN_SYSTEM_COLORS.grayscale['250'],
  },
  borderColors: {
    ...DEFAULT_DESIGN_SYSTEM_THEME.borderColors,
    selectedItem: DESIGN_SYSTEM_COLORS.grayscale['1000'],
    presentationNameDivider: DESIGN_SYSTEM_COLORS.blue['800'],
    presentationNav: DESIGN_SYSTEM_COLORS.blue['800'],
  },
  boxShadows: {
    ...DEFAULT_DESIGN_SYSTEM_THEME.boxShadows,
  },
  sectionBreak: {
    ...DEFAULT_DESIGN_SYSTEM_THEME.sectionBreak,
  },
} as const;

export const STUDIO_DESIGN_SYSTEM_THEME: Partial<DesignSystemTheme> = {
  bgColors: {
    ...DEFAULT_DESIGN_SYSTEM_THEME.bgColors,
    comboboxToken: DESIGN_SYSTEM_COLORS.purple['100'],
    svgGraphicDefaultFill: DESIGN_SYSTEM_COLORS.purple['600'],
    svgGraphicDarkFill: DESIGN_SYSTEM_COLORS.purple['700'],
    presentationHeaderSm: DESIGN_SYSTEM_COLORS.grayscale['900'],
    iconSquareHover: DESIGN_SYSTEM_COLORS.purple['200'],

    // Default color for panel headers for lists and forms
    // see example talent search -> representatives lits -> create new list
    panelHeader: DESIGN_SYSTEM_COLORS.purple['700'],
    headerBanner: DESIGN_SYSTEM_COLORS.purple['200'],
  },
  textColors: {
    ...DEFAULT_DESIGN_SYSTEM_THEME.textColors,

    // Default color for panel headers text for lists and forms
    // see example talent search -> representatives lits -> create new list
    panelHeader: DESIGN_SYSTEM_COLORS.grayscale['0'],
    selectionTag: DESIGN_SYSTEM_COLORS.grayscale['900'],
    selectionTagHighlight: DESIGN_SYSTEM_COLORS.grayscale['0'],
  },
  borderColors: {
    ...DEFAULT_DESIGN_SYSTEM_THEME.borderColors,
  },
  boxShadows: {
    ...DEFAULT_DESIGN_SYSTEM_THEME.boxShadows,
    selectionTag: DESIGN_SYSTEM_COLORS.grayscale['900'],
    selectionTagHighlight: 'rgba(97, 30, 239, 0.2)',
  },
  sectionBreak: {
    ...DEFAULT_DESIGN_SYSTEM_THEME.sectionBreak,
  },
  /**
   * Used for the step wizard molecule component
   *
   * completedStep, activeStep and Step values are for the header bar
   * wizard step values
   *
   * Example design here
   * https://www.figma.com/file/Lw7DgrLZj850OsQTJOKAOs/JIRA---B21-28339---Casting---Project-Creation---%22Quick-Project-Creation%22-workflow?node-id=1762-25782&t=T95RixqUsqvs64s7-0
   */
  wizard: {
    background: DESIGN_SYSTEM_COLORS.grayscale[50],

    titleHeader: DESIGN_SYSTEM_COLORS.grayscale[0],
    completedStepBackgroundColor: DESIGN_SYSTEM_COLORS.purple[500],
    completedStepBorder: DESIGN_SYSTEM_COLORS.purple[500],

    activeStepBackgroundColor: DESIGN_SYSTEM_COLORS.grayscale[0],
    activeStepColor: DESIGN_SYSTEM_COLORS.purple[500],
    activeStepBorder: DESIGN_SYSTEM_COLORS.purple[500],

    stepBackgroundColor: DESIGN_SYSTEM_COLORS.grayscale[0],
    stepColor: DESIGN_SYSTEM_COLORS.grayscale[550],
    stepBorder: DESIGN_SYSTEM_COLORS.grayscale[550],

    footer: {
      border: DESIGN_SYSTEM_COLORS.grayscale[350],
      background: DESIGN_SYSTEM_COLORS.grayscale[0],
    },
  },
} as const;

export const STUDIO_DESIGN_SYSTEM_DARK_THEME: Partial<DesignSystemTheme> = {
  bgColors: {
    ...DEFAULT_DESIGN_SYSTEM_DARK_THEME.bgColors,
    svgGraphicDefaultFill: DESIGN_SYSTEM_COLORS.purple['600'],
    svgGraphicDarkFill: DESIGN_SYSTEM_COLORS.purple['700'],
    listItem: DESIGN_SYSTEM_COLORS.grayscale['900'],
    presentationHeaderMd: DESIGN_SYSTEM_COLORS.blue['900'],
    presentationHeaderSm: DESIGN_SYSTEM_COLORS.grayscale['900'],
  },
  /**
   * The standard text colors as defined in the design system:
   * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=209%3A1589&t=TJC8mLYKTtZvByeD-0
   */
  textColors: {
    ...DEFAULT_DESIGN_SYSTEM_DARK_THEME.textColors,
  },
  borderColors: {
    ...DEFAULT_DESIGN_SYSTEM_DARK_THEME.borderColors,
    presentationNameDivider: DESIGN_SYSTEM_COLORS.blue['900'],
    presentationNav: DESIGN_SYSTEM_COLORS.blue['900'],
  },
  boxShadows: {
    ...DEFAULT_DESIGN_SYSTEM_DARK_THEME.boxShadows,
  },
  sectionBreak: {
    ...DEFAULT_DESIGN_SYSTEM_DARK_THEME.sectionBreak,
    /**
     * The main dividing line between the heading and the body of any given page
     * See "Page Title":
     * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1155%3A13&t=TJC8mLYKTtZvByeD-0
     */
    page: DESIGN_SYSTEM_COLORS.grayscale['800'],
    /**
     * The general dividing line between sections of a page when the background
     * is all the same color
     * See "Role - Expanded" as an example:
     * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1176%3A21792&t=TJC8mLYKTtZvByeD-0
     */
    divider: DESIGN_SYSTEM_COLORS.grayscale['350'],
  },
} as const;
